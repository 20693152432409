import request from '@/utils/request'

// 资产分类
export function getAssetCategory(data) {
  return request({
    url: '/api/app/asset-category',
    method: 'get',
    params: data
  })
}

export function setAssetCategory(data) {
    return request({
      url: '/api/app/asset-category',
      method: 'post',
      data
    })
}

export function delAssetCategory(data) {
    return request({
      url: '/api/app/asset-category/' + data.id,
      method: 'delete',
      params: data
    })
}

export function updateAssetCategory(data) {
    return request({
      url: '/api/app/asset-category/' + data.id,
      method: 'put',
      data
    })
}

// 资产
export function searchAsset(data) {
  return request({
    url: '/api/app/asset/search',
    method: 'get',
    params: data
  })
}

export function setAsset(data) {
  return request({
    url: '/api/app/asset',
    method: 'post',
    data
  })
}

export function getAssetList(data) {
  return request({
    url: '/api/app/asset',
    method: 'get',
    params: data
  })
}

export function getAssetDetail(data) {
  return request({
    url: '/api/app/asset/' + data.id,
    method: 'get',
    params: data
  })
}

export function updateAsset(data) {
  return request({
    url: '/api/app/asset/' + data.id,
    method: 'put',
    data
  })
}

export function delAsset(data) {
  return request({
    url: '/api/app/asset/' + data.id,
    method: 'delete',
    params: data
  })
}

// 资产使用者分类
export function getAssetUserCategory(data) {
    return request({
        url: '/api/app/asset-user-category',
        method: 'get',
        params: data
    })
}

export function setAssetUserCategory(data) {
    return request({
      url: '/api/app/asset-user-category',
      method: 'post',
      data
    })
}

export function delAssetUserCategory(data) {
    return request({
      url: '/api/app/asset-user-category/' + data.id,
      method: 'delete',
      params: data
    })
}

export function updateAssetUserCategory(data) {
    return request({
      url: '/api/app/asset-user-category/' + data.id,
      method: 'put',
      data
    })
}

// 资产使用者
export function searchAssetUser(data) {
  return request({
    url: '/api/app/asset-user/list',
    method: 'get',
    params: data
  })
}

export function searchEmployees(data) {
  return request({
    url: `/api/app/asset-user/employees`,
    method: 'get',
    params: data
  })
}

export function searchAssetAreas(data) {
  return request({
    url: `/api/app/asset-user/areas`,
    method: 'get',
    params: data
  })
}

export function getAssetUserBindInfo(data) {
  return request({
    url: '/api/app/asset-user/bind-info',
    method: 'get',
    params: data
  })
}

export function setAssetUserBindInfo(data) {
  return request({
    url: '/api/app/asset-user/bind',
    method: 'post',
    data
  })
}

export function setAssetUser(data) {
  return request({
    url: '/api/app/asset-user',
    method: 'post',
    data
  })
}

export function getAssetUserList(data) {
  return request({
    url: '/api/app/asset-user',
    method: 'get',
    params: data
  })
}

export function getAssetUserDetail(data) {
  return request({
    url: '/api/app/asset-user/' + data.id,
    method: 'get',
    params: data
  })
}

export function updateAssetUser(data) {
  return request({
    url: '/api/app/asset-user/' + data.id,
    method: 'put',
    data
  })
}

export function delAssetUser(data) {
  return request({
    url: '/api/app/asset-user/' + data.id,
    method: 'delete',
    params: data
  })
}

// 事业部管理
export function searchCompany(data) {
  return request({
    url: '/api/app/company/list',
    method: 'get',
    params: data
  })
}

export function setCompany(data) {
  return request({
    url: '/api/app/company',
    method: 'post',
    data
  })
}

export function getCompanyList(data) {
  return request({
    url: '/api/app/company',
    method: 'get',
    params: data
  })
}

export function getCompanyDetail(data) {
  return request({
    url: '/api/app/company/' + data.id,
    method: 'get',
    params: data
  })
}

export function updateCompany(data) {
  return request({
    url: '/api/app/company/' + data.id,
    method: 'put',
    data
  })
}

export function delCompany(data) {
  return request({
    url: '/api/app/company/' + data.id,
    method: 'delete',
    params: data
  })
}

// 仓库管理
export function searchWarehouse(data) {
  return request({
    url: '/api/app/warehouse/list',
    method: 'get',
    params: data
  })
}

export function setWarehouse(data) {
  return request({
    url: '/api/app/warehouse',
    method: 'post',
    data
  })
}

export function getWarehouseList(data) {
  return request({
    url: '/api/app/warehouse',
    method: 'get',
    params: data
  })
}

export function getWarehouseDetail(data) {
  return request({
    url: '/api/app/warehouse/' + data.id,
    method: 'get',
    params: data
  })
}

export function updateWarehouse(data) {
  return request({
    url: '/api/app/warehouse/' + data.id,
    method: 'put',
    data
  })
}

export function delWarehouse(data) {
  return request({
    url: '/api/app/warehouse/' + data.id,
    method: 'delete',
    params: data
  })
}

