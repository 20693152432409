<style lang="scss" scoped>
.page-stock-token {
    .login-iframe {
      width: 100%;
      height: 100%;
      border: 0;
      position: absolute;
      opacity: .8;
      left: 0;
      top: 0;
    }
    /deep/ .stock-card {
        position: relative;
        top: 20px;
        margin: 0 auto;
        min-width: 300px;
        width: 92%;
        height: 95vh;
        .el-card__body {
            padding: 20px 6px;
            height: calc(100% - 40px);
            .el-scrollbar {
                height: 100%;
                max-height: 100%;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
        }
        .el-table .cell {
            padding-left: 5px;
            padding-right: 5px;
        }
        .title {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .el-button {
            width: 100%;
        }
    }
    .login-card {
        position: relative;
        top: 25vh;
        margin: 0 auto;
        min-width: 300px;
        max-width: 400px;
        width: 92%;
        .title {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .el-button {
            width: 100%;
        }
    }
}
</style>

<template>
    <div class="page-stock-token">
        <iframe src="static/particle/18.html" class="login-iframe" />
        <el-card class="stock-card" v-if="isBindinged === true">
            <el-scrollbar :ref="refScrollbar">
                <div class="title">{{ title }}</div>
                <el-tag>总数量：{{ tableData.length }}</el-tag>
                <el-table :data="tableData" size="medium">
                    <el-table-column label="" type="index" width="40"></el-table-column>
                    <el-table-column label="商品名称" prop="asset.name"></el-table-column>
                    <el-table-column label="序列号" width="100" align="center" prop="serialNumber"></el-table-column>
                    <el-table-column label="数量" width="50" align="center" prop="count"></el-table-column>
                </el-table>
                <el-alert class="mrgt20" title="注：请妥善保管，遗失需照价赔偿。" type="warning" :closable="false"></el-alert>
                <el-button class="mrgt5" type="primary" @click="_save()" v-if="stockStatus === 0">确定</el-button>
                <el-button class="mrgt5" type="info" v-if="stockStatus === 1" :disabled="isDisabledBtn">已授权</el-button>
                <el-button class="mrgt5" type="info" v-if="stockStatus === -1" :disabled="isDisabledBtn">已过期</el-button>
            </el-scrollbar>
        </el-card>
        <el-card class="login-card" shadow="hover" v-if="isBindinged === false">
            <div class="title">员工绑定</div>
            <el-form :model="tokenData" :rules="formRules" :ref="formRefName">
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="" prop="name">
                            <el-input v-model="tokenData.name" placeholder="请输入姓名">
                                <i slot="prefix" class="el-input__icon fa fa-user"></i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="" prop="phoneNumber">
                            <el-input v-model="tokenData.phoneNumber" placeholder="请输入电话">
                                <i slot="prefix" class="el-input__icon fa fa-phone"></i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="_bind()">绑定</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "pageStockToken",
    data() {
        return {
            refScrollbar: "refStockTokenScrollbar",
            title: "出库",
            tableData: [],
            tokenData: {
                name: "",
                phoneNumber: ""
            },
            formRefName: "refStockToken",
            formRules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }]
            },
            isWechatBrowser: false,
            isBindinged: null,
            stockToken: "",
            stockStatus: null,
            isDisabledBtn: false
        }
    },
    async mounted() {
        this.isWechatBrowser = window.$util.getBrowser().wechat || false
        if (this.isWechatBrowser) {
            let url = location.href
            let authed = window.$util.getUrlParam(url, "authed") || false
            this.stockToken = window.$util.getUrlParam(url, "token") || ""
            if (!this.stockToken) {
                this.errorMsg("未获取到Token参数!")
                return false
            }
            if (authed) {
                // 获取绑定信息
                await this.getBindInfo()
            } else {
                this.toWechatAuth()
            }
        } else {
            location.href = "/"
        }
    },
    methods: {
        toWechatAuth() {
            // http://uat.asset.scjoy.net/#/stockToken?token=2350ca6386b64673b3f6b1216c712869&authed=true
            let path = "/#/stockToken?token=" + this.stockToken
            path = encodeURIComponent(path)
            console.log(path)
            location.href = "/Wechat/BaseAuthorize?returnUrl=" + path // http://uat.asset.scjoy.net
        },
        async getBindInfo() {
            window.$common.fullLoading()
            await funInfomation.getAssetUserBindInfo().then(res => {
                if (res && res.name && res.phone) {
                    // 已绑定
                    this.isBindinged = true
                    // 获取列表
                    this.getStockBills()
                } else {
                    // 未绑定
                    this.isBindinged = false
                }
            })
            window.$common.closeFullLoading()
        },
        async getStockBills() {
            await funStock.getStockHandleToken({ token: this.stockToken }).then(res => {
                this.tableData = []
                this.stockToken = res.token || this.stockToken
                this.title = res.subject || "出库"
                this.stockStatus = res.status
                this.isDisabledBtn = !!res.status
                if (res.items && res.items.length > 0) {
                    for (let i in res.items) {
                        let item = res.items[i]
                        let temp = {
                            count: item.count,
                            serialNumber: "",
                            asset: item.asset
                        }
                        // 有序列号的
                        if (item.asset && item.asset.hasSerialNumber) {
                            item.serialNumbers.forEach(x => {
                                temp = {
                                    count: 1,
                                    serialNumber: x,
                                    asset: item.asset
                                }
                                this.tableData.push(temp)
                            })
                        } else {
                            this.tableData.push(temp)
                        }
                    }
                }
            })
            this.scrollbarUpdate()
        },
        _bind() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    funInfomation.setAssetUserBindInfo(this.tokenData).then(res => {
                        this.isBindinged = true
                        // 获取列表
                        this.getStockBills()
                        this.successMsg("绑定成功!")
                    })
                }
            })
        },
        _save() {
            this.confirm("确定已认真核对数据?").then(() => {
                funStock.setStockAuthHandleToken({ token: this.stockToken }).then(res => {
                    this.successMsg("操作成功!")
                    this.isDisabledBtn = true
                })
            })
        },
        scrollbarUpdate() {
            setTimeout(() => {
                this.$refs[this.refScrollbar].update()
            }, 100)
        }
    }
}
</script>